import { GLOBAL_ERROR } from "../constant";
import customHttp from "../custom_http";
import { handleLogoutError } from "./action_common";

export function sendPricing(project_id, project_role_id, data, type) {
  const url = `https://ih-oms-api.awign.com/unauthenticated_api/v1/projects/${project_id}/project_roles/${project_role_id}/leads`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function sendPricingLeadStatus(project_id, project_role_id, lead_id, data) {
  const url = `https://ih-oms-api.awign.com/unauthenticated_api/v1/projects/${project_id}/project_roles/${project_role_id}/leads/${lead_id}/status`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function proctorLeadAttributes(id, data) {
  const url = `https://ih-oms-api.awign.com/projects/api/v1/projects/${id}/lead_attributes/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}
