import { DEVICE_TYPE, FROM_URL, VALID_ACCESS, BREADCRUMBS, SIDEBAR_ACTIVE, LOADING } from "../constant";

const INITIAL_STATE = {
  deviceType: "Smartphone",
  breadcrumbs: [],
  fromUrl: "",
  hasValidAccess: true,
  sidebarActive: "",
  loading: false,
};

export default function commonReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DEVICE_TYPE:
      return { ...state, deviceType: action.payload };
    case VALID_ACCESS:
      return { ...state, hasValidAccess: action.payload };
    case SIDEBAR_ACTIVE:
      return { ...state, sidebarActive: action.payload };
    case FROM_URL:
      localStorage.setItem("fromUrl", action.payload);
      return { ...state, fromUrl: action.payload };
    case BREADCRUMBS:
      return { ...state, breadcrumbs: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
