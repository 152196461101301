import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { DOMAIN, OLD_WEBSITE_URL } from "../../../constant";

const style = {
  button: { borderRadius: "6px", backgroundColor: "#1a9cf6" },
  display: { backgroundColor: "white", borderTop: "solid 1px #ecf3f5" },
  disclaimerIcon: {
    width: "35px",
    height: "19px",
  },
  accept: { color: "#009cf6", textDecoration: "underline" },
};

const Disclaimer = (props) => {
  const Cookie = new Cookies();
  const [show, setshow] = useState(false);
  useEffect(() => {
    setshow(true);
  }, []);
  const handleClose = () => {
    Cookie.set("disclaimer_clicked", JSON.stringify(true), { maxAge: 31556926, domain: DOMAIN });
    setshow(false);
  };
  if (show && !Cookie.get("disclaimer_clicked")) {
    return (
      <div className="fixed-bottom row align-items-center py-3" style={style.display}>
        <div className="offset-xl-2 offset-md-1 col-xl-6 col-md-8 col-12 mb-md-0 mb-2 pr-md-0">
          <div className="d-flex">
            <div className="mr-3 ml-md-0 ml-4" style={style.disclaimerIcon}>
              <img src="/images/cookie.svg" alt="" />
            </div>
            <div className="font-12 black mr-md-0 mr-3">
              This website uses proprietary and third-party cookies, to offer you better browsing experience. By continuing to browse, you agree to their use in accordance with our{" "}
              <a href={`${OLD_WEBSITE_URL}/cookie_policy`} target="_blank" rel="noopener noreferrer" style={style.accept}>
                Cookie Policy
              </a>
            </div>
          </div>
        </div>
        <div className="offset-xl-1 offset-md-0 text-md-right text-xl-left offset-7 col-md-2 col-6 font-12 pl-md-0">
          <button style={style.button} className="text-light border-0 py-2 px-3 medium" onClick={handleClose}>
            Accept Cookies
          </button>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Disclaimer;
