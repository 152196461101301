import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import Parent from "./route";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

const RELEASE = "0.1.3";
if (process.env.REACT_APP_SERVE === "production") {
  Sentry.init({
    dsn: "https://5b8fd4744a77464a8a3d86efe572485a@sentry.io/2115309",
    release: RELEASE,
    ignoreErrors: [
      `Cannot read property 'getReadModeRender' of undefined`,
      `Cannot read property 'getReadModeConfig' of undefined`,
      `Cannot read property 'getReadModeExtract' of undefined`,
      "Failed to fetch",
      "ResizeObserver loop limit exceeded",
      "Network Error",
      "Illegal invocation",
      "Non-Error promise rejection captured with value: failure",
      "privateSpecialRepair is not defined",
      "Non-Error promise rejection captured with keys: data, status, success",
      "Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.",
    ],
  });
}

export const store = createStore(reducers, applyMiddleware(reduxThunk, promise));

ReactDOM.render(
  <Provider store={store}>
    <Parent />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
