import axios from "axios";
import Cookies from "universal-cookie";
import { DOMAIN, SECURE } from "./constant";
import { uuidv4 } from "./components/shared/custom_html_elements/utils";

const curUrl = DOMAIN;
const customHttp = axios.create({ withCredentials: false });
const cookies = new Cookies();
const currentUser = cookies.get("currentUser");
const saas_org_id = currentUser && currentUser.data.data.headers["saas-org-id"];

customHttp.interceptors.request.use(
  (config) => {
    let access_token = cookies.get("access-token", { path: "/", domain: `${curUrl}` });
    let uid = cookies.get("uid", { path: "/", domain: `${curUrl}` });
    let client = cookies.get("client", { path: "/", domain: `${curUrl}` });
    if (access_token) {
      config.headers["access-token"] = access_token;
      config.headers.uid = uid;
      config.headers.client = client;
    }
    config.headers["caller_id"] = "business-ui";
    config.headers["saas-org-id"] = saas_org_id || "nil";
    config.headers["X-Request-Id"] = `clients_ui_${uuidv4()}`;
    config.headers["X_CLIENT_ID"] = "clients_ui";
    return config;
  },
  (error) => Promise.reject(error)
);

customHttp.interceptors.response.use(
  (response) => {
    const currentUser = cookies.get("currentUser");
    if (response.config.headers["access-token"]) {
      if (currentUser) cookies.set("currentUser", currentUser, { path: "/", domain: `${curUrl}`, maxAge: 86400, secure: SECURE });
      cookies.set("access-token", `${response.config.headers["access-token"]}`, { path: "/", domain: `${curUrl}`, maxAge: 86400, secure: SECURE });
      cookies.set("uid", `${response.config.headers.uid}`, { path: "/", domain: `${curUrl}`, maxAge: 86400, secure: SECURE });
      cookies.set("client", `${response.config.headers.client}`, { path: "/", domain: `${curUrl}`, maxAge: 86400, secure: SECURE });
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default customHttp;
