import { GLOBAL_ERROR, MAINTENANCE_ERROR, GLOBAL_SUCCESS, SHOW_OFFLINE, HIDE_OFFLINE } from "../constant";

const INITIAL_STATE = {
  globalError: null,
  maintenanceError: null,
  globalSuccess: null,
  offline: { type: null, message: null, show: false, delay: null },
};

export default function errorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GLOBAL_ERROR:
      // console.log(action.payload);
      return { ...state, globalError: action.payload };
    case GLOBAL_SUCCESS:
      return { ...state, globalSuccess: action.payload };
    case MAINTENANCE_ERROR:
      return { ...state, maintenanceError: action.payload };
    case SHOW_OFFLINE:
      return { ...state, offline: { type: action.payload.type, message: action.payload.msg, show: true, delay: action.payload.delay } };
    case HIDE_OFFLINE:
      return { ...state, offline: { type: null, message: null, show: false } };
    default:
      return state;
  }
}
