import React from "react";
import moment from "moment";
import * as Yup from "yup";

export const windowWidth = () => {
  if (window.innerWidth <= 768) {
    return "Smartphone";
  }
  return "Desktop";
};

export const capitalizeFirstLetter = (inputString = "") => {
  if (inputString === null) {
    return null;
  }
  return inputString.replace(/_/g, " ").replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

export const incrementalString = (inputString) => {
  let sequence = inputString.replace(/[^0-9]/g, "");
  if (sequence === "") {
    return inputString + "1";
  }
  return inputString.replace(/[0-9]/g, "") + (parseInt(sequence) + 1).toString();
};

export const timeConverter = (time) => {
  let hours;
  let minutes;
  hours = new Date(moment(time)).getHours();
  minutes = new Date(moment(time)).getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  } else if (minutes === 0) {
    minutes = "00";
  }
  if (parseInt(hours) === 12) {
    return `${hours}:${minutes} PM`;
  }
  if (parseInt(hours) >= 13) {
    hours -= 12;
    return `${hours}:${minutes} PM`;
  }
  return `${hours}:${minutes} AM`;
};

export const isEmpty = (obj) => {
  // THIS EMPTY CHECK DOESN'T VALIDATE NUMBERS. KEEP IN MIND
  //var hasOwnProperty = Object.prototype.hasOwnProperty;
  // null and undefined are "empty"
  // eslint-disable-next-line
  if (obj == null) return true;
  if (obj === (undefined || "undefined")) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  if (typeof obj === "number") return false;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  if (obj instanceof Date) return false;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const reject = (obj, keys) => {
  return Object.keys(obj)
    .filter((k) => !keys.includes(k))
    .map((k) => Object.assign({}, { [k]: obj[k] }))
    .reduce((res, o) => Object.assign(res, o), {});
};

export const accessObj = (t, obj) => {
  t = t.replace(/\[/g, ".");
  t = t.replace(/\]/g, "");
  t = t.split(".");
  let c = obj;
  for (var i = 0; i < t.length; i++) {
    try {
      c = c[t[i]];
    } catch (e) {
      return "";
    }
  }
  return c;
};

export const isEmptyArrayValue = (val) => {
  return Array.isArray(val) && val[0] === "";
};

export const rejectParams = (obj, keys) => {
  return reject(obj, keys);
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      removeEmpty(obj[key]);
    } else if (obj[key] === undefined || obj[key] === "" || obj[key] === null || obj[key].length === 0) {
      delete obj[key];
    }
  });
  return obj;
};

export const monthConverter = (month) => {
  switch (month) {
    case 1:
      return "Jan";

    case 2:
      return "Feb";

    case 3:
      return "Mar";

    case 4:
      return "Apr";

    case 5:
      return "May";

    case 6:
      return "June";

    case 7:
      return "July";

    case 8:
      return "Aug";

    case 9:
      return "Sep";

    case 10:
      return "Oct";

    case 11:
      return "Nov";

    case 12:
      return "Dec";

    default:
      return month;
  }
};

export const dateConverter = (time) => {
  const curDay = moment(time).format("dddd");
  const curYear = new Date(moment(time)).getFullYear();
  const curMonth = monthConverter(new Date(moment(time)).getMonth() + 1);
  const curDate = new Date(moment(time)).getDate();

  return `${curDay}, ${curDate} ${curMonth}, ${curYear}`;
};

export const dateFormatter = (date) => `${date.getUTCFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const timeFormatter = (time) => `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;

export const dateTimeParser = (dateTime) => {
  const day = new Date(moment(dateTime));
  return day;
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const stringDeparameterize = (str1) => {
  return str1.trim().replace(/-/g, " ");
};

export const stringParameterize = (str1) => {
  return str1
    .trim()
    .replace(/[^a-zA-Z0-9 -]/, "")
    .replace(/\s/g, "-");
};

export const randomNumber = (min, max) => {
  let random = Math.floor(Math.random() * (+max - +min)) + +min;
  return random;
};

export const truncateString = (str, length) => {
  if (str === undefined) {
    return "";
  }
  if (str.length > length) {
    return str.substring(0, length) + "...";
  } else {
    return str;
  }
};

export const randomStringGenerator = (length) =>
  Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
    .toString(36)
    .slice(1);

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const operatorMap = [
  { value: "eq", label: "Equals to" },
  { value: "neq", label: "Not Equal to" },
  { value: "contains", label: "Contains" },
  { value: "not_contains", label: "Does Not Contains  " },
  { value: "empty", label: "Is Empty" },
  { value: "not_empty", label: "Not Empty" },
  { value: "starts_with", label: "Starts With" },
  { value: "ends_with", label: "Ends With" },
  { value: "between", label: "Between" },
  { value: "includes", label: "Includes" },
  { value: "previous", label: "Previous" },
  { value: "next", label: "Next" },
  { value: "current", label: "Current" },
  { value: "lt", label: "Before" },
  { value: "gt", label: "After" },
  { value: "before", label: "Before" },
  { value: "after", label: "After" },
  { value: "on", label: "On" },
];

export const allOperators = {
  text: [
    { value: "eq", label: "Equals to" },
    { value: "neq", label: "Not Equal to" },
    { value: "contains", label: "Contains" },
    { value: "not_contains", label: "Does Not Contains  " },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
    { value: "starts_with", label: "Starts With" },
    { value: "ends_with", label: "Ends With" },
    { value: "previous", label: "Previous" },
    { value: "next", label: "Next" },
    { value: "current", label: "Current" },
    { value: "before", label: "Before" },
    { value: "after", label: "After" },
    { value: "on", label: "On" },
  ],
  email: [
    { value: "eq", label: "Equals to" },
    { value: "neq", label: "Not Equal to" },
    { value: "contains", label: "Contains" },
    { value: "not_contains", label: "Does Not Contains  " },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
    { value: "starts_with", label: "Starts With" },
    { value: "ends_with", label: "Ends With" },
    { value: "previous", label: "Previous" },
  ],
  number: [
    { value: "eq", label: "Equals to" },
    { value: "gt", label: "Greater than" },
    { value: "gte", label: "Greater than or equal to" },
    { value: "lt", label: "Less than" },
    { value: "lte", label: "Less than equal to" },
    { value: "neq", label: "Not Equal to" },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
    { value: "between", label: "Between" },
  ],
  single_select: [
    { value: "eq", label: "Equals to" },
    { value: "neq", label: "Not Equal to" },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
    { value: "includes", label: "Includes" },
  ],
  multi_select: [
    { value: "includes", label: "Includes" },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
  ],
  date_time: [
    { value: "previous", label: "Previous" },
    { value: "next", label: "Next" },
    { value: "current", label: "Current" },
    { value: "before", label: "Before" },
    { value: "after", label: "After" },
    { value: "on", label: "On" },
    { value: "between", label: "Between" },
    { value: "is_empty", label: "Empty" },
    { value: "not_empty", label: "Not Empty" },
  ],
  datetime_range: [
    { value: "between", label: "Between" },
    { value: "is_empty", label: "Empty" },
    { value: "not_empty", label: "Not Empty" },
  ],
};

export const timeOptions = [
  { value: "minutes", label: "minutes" },
  { value: "hours", label: "hours" },
  { value: "days", label: "days" },
  { value: "weeks", label: "weeks" },
  { value: "months", label: "months" },
  { value: "years", label: "years" },
];

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
}

export const getYupString = (renderType, required) => {
  let validate;
  switch (renderType) {
    case "string":
      validate = Yup.string().max(200, "Max length should be less than 200 characters");
      break;
    case "short_text":
      validate = Yup.string().max(200, "Max length should be less than 200 characters");
      break;
    case "text":
      validate = Yup.string().max(2000, "Max length should be less than 2000 characters");
      break;
    case "long_text":
      validate = Yup.string().max(2000, "Max length should be less than 2000 characters");
      break;
    case "email":
      validate = Yup.string().email("Enter a valid email");
      break;
    case "float":
      validate = Yup.number("Enter a valid number").transform(emptyStringToNull);
      break;
    case "integer":
      validate = Yup.number("Enter a valid number")
        .positive("Enter positive number")
        .test("int", "Must be integer", (val) => {
          return val % 1 === 0 || val === "" || val === undefined;
        })
        .transform(emptyStringToNull);
      break;
    case "phone":
      validate = Yup.string().matches(/^[6-9]\d{9}$/, "Phone number is not valid");
      break;
    case "time":
      validate = Yup.string().nullable();
      break;
    case "time_range":
      validate = Yup.array().of(Yup.string().required("Required").nullable()).nullable();
      break;
    default:
      validate = Yup.string();
  }

  if (renderType === "single_select_dropdown" && required === true) {
    validate = Yup.object().shape({
      value: Yup.string().required("Required").nullable(),
    });
  } else if (required === true) {
    validate = validate.required("Required").nullable();
  } else {
    validate = validate.nullable();
  }
  return validate;
};

export const getImg = (type) => {
  let img =
    type === "string"
      ? "ic_short_text.svg"
      : type === "text"
      ? "ic_long_text.svg"
      : type === "email"
      ? "ic_email.svg"
      : type === "phone"
      ? "ic_phone.svg"
      : type === "number" || type === "integer" || type === "float"
      ? "ic_number.svg"
      : "ic_long_text.svg";
  return img;
};

export const deepCopyFunction = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null || inObject instanceof Date) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = typeof value === "object" && value !== null ? deepCopyFunction(value) : value;
  }

  return outObject;
};

export const formatValue = (value, render_type) => {
  // Handle array type for all render type
  if (!isEmpty(value)) {
    if (render_type === "date") {
      let date = value instanceof Date ? moment(value, "YYYY-MM-DD").format("YYYY-MM-DD") : value;
      return date;
    } else if (render_type === "date_time") {
      let datetime = value instanceof Date ? moment(value, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : value;
      return datetime;
    } else if (render_type === "datetime_range") {
      return value;
    } else if (render_type === "single_select_dropdown") {
      return value.value;
    } else if (render_type === "multi_select_dropdown" && !isEmpty(value)) {
      let values = value.map(function (item) {
        return item["value"] ? item["value"] : item;
      });
      return values;
    } else if (render_type === "time") {
      let time = value instanceof Date ? moment(value).format("HH:mm:ss") : value;
      return time;
    } else if (render_type === "time_range") {
      let startTime = value[0] instanceof Date ? moment(value[0]).format("HH:mm:ss") : value[0];
      let endTime = value[1] instanceof Date ? moment(value[1]).format("HH:mm:ss") : value[1];
      var timeRange = [startTime, endTime];
      return timeRange;
    } else if (["integer", "float"].includes(render_type)) {
      if (Array.isArray(value)) {
        return value.map((n) => Number(n));
      } else {
        return Number(value);
      }
    }
    return value;
  } else {
    return null;
  }
};

export const getFileFormat = (type) => {
  let format =
    type === "image" ? "image/*" : type === "audio" ? ".mp3,.wav,.ogg,.ogv" : type === "video" ? "video/*" : type === "pdf" ? ".pdf" : type === "signature" ? "image/*" : "*";
  return format;
};

export const parseValues = (value, type = null) => {
  if (isEmpty(value)) {
    return null;
  }
  if (["date", "date_time", "time", "time_range", "date_range", "datetime_range", "current_location"].includes(type)) {
    return <span>{value.toString()}</span>;
  }
  if (Array.isArray(value)) {
    return (
      <div>
        {value.map(function (value, value_index) {
          return (
            <span className="array-badge" key={value_index}>
              {value}
            </span>
          );
        })}
      </div>
    );
  } else if (typeof (value === "object")) {
    const keys = Object.keys(value);
    if (!isEmpty(keys)) {
      return (
        <div>
          {keys.map(function (key, index) {
            return (
              <div key={index} className="pr-2">
                <span className="bold">{key}:</span> {value[key]}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  } else {
    return <span>{value}</span>;
  }
};

export const generateQueryString = (queryObj, nesting = "") => {
  const pairs = Object.entries(queryObj).map(([key, val]) => {
    // Handle a second base case where the value to encode is an array
    if (Array.isArray(val)) {
      return val.map((subVal) => [nesting + key, subVal].map(escape).join("=")).join("&");
    } else if (typeof val === "object") {
      return generateQueryString(val, nesting + `${key}::`);
    } else {
      return [nesting + key, val].map(escape).join("=");
    }
  });
  return decodeURIComponent(pairs.join("&"));
};

export const parseQueryString = (str) => {
  if (isEmpty(str)) {
    return [{}, {}];
  }
  str = decodeURIComponent(str).replace("?", "");
  let queryObj = {};
  let additionalObj = {};
  let filters = [],
    filter_key = "",
    filter_operator = "",
    filter_value = "";
  // eslint-disable-next-line
  str.split("&").map(function (item) {
    if (item.includes("page") || item.includes("limit") || item.includes("sort_column") || item.includes("sort_order")) {
      additionalObj[item.split("=")[0]] = item.split("=")[1];
    } else {
      filter_key = item.split("::")[0];
      if (item.split("::")[1].split("=")[0] === "operator") {
        filter_operator = item.split("::")[1].split("=")[1];
      }
      if (filters.includes(filter_key)) {
        filter_value = item.split("::")[1].split("=")[1];
        if (!isEmpty(queryObj[filter_key]["value"])) {
          let existingValue = queryObj[filter_key]["value"];
          if (Array.isArray(existingValue)) {
            queryObj[filter_key]["value"].push(filter_value);
          } else {
            let updatedValue = existingValue.split(",").concat(filter_value);
            queryObj[filter_key]["value"] = updatedValue;
          }
        } else {
          queryObj[filter_key]["value"] = filter_value;
        }
      } else {
        queryObj[filter_key] = {};
        queryObj[filter_key]["operator"] = filter_operator;
      }
      filters.push(filter_key);
    }
  });
  return [queryObj, additionalObj];
};

export const generateQuery = (query) => {
  const keys = Object.keys(query);
  const val = Object.values(query);
  const test = val.map((elm, index) =>
    typeof elm === "string" || typeof elm === "number"
      ? `${keys[index]}=${elm}`
      : Object.entries(elm).map((el) => {
          if (Array.isArray(el[1])) {
            const arr = el[1].map((v) => (isNaN(v) ? v.replace(/,/g, "%2C") : v));
            return [el[0], arr.join(":::")].join("=");
          } else {
            return el.join("=");
          }
        })
  );
  // Joining keys with operator and value
  const test2 = test.map((elm, index) => {
    if (Array.isArray(elm)) {
      return elm.map((el) => keys[index] + "::" + el).join("&");
    } else {
      return elm;
    }
  });
  const final_query = test2.join("&");
  return final_query;
};

export const parseQuery = (search_term) => {
  const keys_arr = [];
  const parseObj = {};
  const query = search_term.slice(1);
  if (isEmpty(query)) return {};
  const part1 = query.split("&");
  // Separating Non Filters Conditions from query
  const raw_non_filter_conditions = part1.filter((elm) => !elm.match(/:::|::/));
  const non_filter_conditions_array = raw_non_filter_conditions.map((elm) => elm.split("="));
  const non_filter_conditions_obj = Object.fromEntries(non_filter_conditions_array);
  // Separting Filters Conditions from query
  const raw_filter_conditions = part1.filter((elm) => elm.match(/:::|::/));
  // Replacing array values separator with comma
  const raw_filter_conditions_1 = raw_filter_conditions.map((elm) => elm.replace(/:::/g, ","));
  // Spliting keys with operator and value
  const raw_filter_conditions_2 = raw_filter_conditions_1.map((elm) => elm.split("::"));
  // Pushing filters conditions keys to keys_arr and assigning filters conditions keys to parseObj
  raw_filter_conditions_2.forEach((elm) => {
    if (!keys_arr.includes(elm[0])) {
      keys_arr.push(elm[0]);
      parseObj[elm[0]] = [];
    }
  });
  // Splitting values of operator and value constants
  const raw_filter_conditions_3 = raw_filter_conditions_2.map((elm) => [elm[0], elm[1].split("=")]);
  // Combining operator array and value array of same key into single array
  raw_filter_conditions_3.map((elm) => (parseObj[elm[0]] = [...parseObj[elm[0]], elm[1]]));
  // Creating object from operator and values array
  keys_arr.map((elm) => (parseObj[elm] = Object.fromEntries(parseObj[elm])));
  // Creating array of values for in and between operator
  keys_arr.forEach((elm) => {
    if (["in", "between"].includes(parseObj[elm]["operator"])) {
      const arr = parseObj[elm]["value"].split(",");
      const arr2 = arr.map((val) => decodeURIComponent(val));
      parseObj[elm]["value"] = arr2;
    }
  });
  const final = { ...non_filter_conditions_obj, payload: { ...parseObj } };
  return final;
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function checkIfMongoId(id) {
  if (id.match(/^[0-9a-fA-F]{24}$/)) {
    return true;
  } else {
    return false;
  }
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const executionStatuses = [
  { value: "added", label: "Added" },
  { value: "approved", label: "Approved" },
  { value: "started", label: "Started" },
  { value: "duration_extended", label: "Duration Extended" },
  { value: "submitted", label: "Submitted" },
  { value: "completed", label: "Completed" },
  { value: "halted", label: "Halted" },
  { value: "rejected", label: "Rejected" },
  { value: "backed_out", label: "Backed Out" },
  { value: "disqualified", label: "Disqualified" },
  { value: "blacklisted", label: "Blacklisted" },
];

export const allocationHeaders = [
  { uid: "execution", column_title: "Execution Id" },
  { uid: "member", column_title: "Member Id" },
];

export function copy() {
  /* Get the text field */
  var copyText = document.getElementById("copy-field");
  /* Select the text field */
  copyText.select();
  /* Copy the text inside the text field */
  document.execCommand("copy");
  /* Alert the copied text */
  console.log(copyText.value);
}

export function filterProjectLevels(projectLevels) {
  let levels_to_exclude = ["UnAuthenticated"];
  return projectLevels.filter((pl) => !levels_to_exclude.includes(pl.name));
}

export function filterProjectRoles(projectRoles) {
  let roles_to_exclude = ["Anonymous"];
  return projectRoles.filter((pr) => !roles_to_exclude.includes(pr.name));
}

export function momentDateFormatter(value, format) {
  if (isEmpty(value)) {
    return value;
  }
  const default_format = "DD/MM/YYYY";
  return moment(value, "YYYY-MM-DD HH:mm:ss").format(default_format);
}

export function momentTimeFormatter(value, format) {
  //return moment(value, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
  // let formattedTime = !isEmpty(value) ? value.split(":").slice(0, -1).join(":") : value;
  return value;
}

export function momentDateTimeFormatter(value, format) {
  if (isEmpty(value)) {
    return value;
  }
  const default_format = "DD/MM/YYYY hh:mm A";
  return moment(value).format(default_format);
}
