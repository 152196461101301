import React from "react";
import { connect } from "react-redux";

const RestrictedText = ({ loading }) => {
  return (
    <div>
      {!loading && (
        <div className="pb-3 w-100 d-flex align-items-center justify-content-center">
          <img src="https://awign-production.s3.ap-south-1.amazonaws.com/office-ui/restricted.png" height="15px" width="12px" alt="restricted" />
          <span className="mx-2">Only authorised people can access this page.</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ common: { loading } }) => ({
  loading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedText);
