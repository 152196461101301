import React from "react";

const NotFound = () => (
  <div className="container-fluid">
    <div className="col-12">
      <div className="row justify-content-center mt-5">
        <div className="col-4 p-5">
          <img src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/404.svg" alt="404" />
        </div>
        <div className="col-4 p-5">
          <div style={{ marginTop: "100px" }}>
            <h1 style={{ fontSize: "60px", letterSpacing: "0.75rem", color: "#606a7b" }}>404</h1>
            <h5 style={{ color: "#1f2d3d", letterSpacing: "0.25rem" }}>PAGE NOT FOUND</h5>
            <p style={{ fontSize: "15px", color: "#606a7b", marginTop: "20px" }}>We can’t find the page you’re looking for</p>
            <a href="/" style={{ fontSize: "15px", color: "#009cf6", marginTop: "20px" }}>
              GO TO HOME
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NotFound;
