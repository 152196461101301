export const GLOBAL_SUCCESS = "GLOBAL_SUCCESS";
export const LOGIN = "LOGIN";
export const DEVICE_TYPE = "DEVICE_TYPE";
export const USER_LOGIN = "USER_LOGIN";
export const LOGOUT = "LOGOUT";
export const SIGNING_IN = "SIGNING_IN";
export const SIGNING_OUT = "SIGNING_OUT";
export const REDIRECT = "REDIRECT";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_OUT_ERROR = "SIGN_OUT_ERROR";
export const FORGOT_ERROR = "FORGOT_ERROR";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const MAINTENANCE_ERROR = "MAINTENANCE_ERROR";
export const RESTRICTED_BROWSERS = ["UC Browser"];
export const ERROR_500 = "Something Went Wrong";
export const ERROR_404 = "Entry Not found";
export const SUCCESS_200 = "Success";
export const CURRENT_CLIENT_DOMAIN = "CURRENT_CLIENT_DOMAIN";
export const CURRENT_CLIENT_NAME = "CURRENT_CLIENT_NAME";
export const FROM_URL = "FROM_URL";
export const SIDEBAR_ACTIVE = "SIDEBAR_ACTIVE";
export const SIDEBAR_STATE = "SIDEBAR_STATE";
export const VALID_ACCESS = "VALID_ACCESS";
export const BREADCRUMBS = "BREADCRUMBS";
export const ORGANISATION = "ORGANISATION";
export const LOADING = "LOADING";
export const SHOW_OFFLINE = "SHOW_OFFLINE";
export const HIDE_OFFLINE = "HIDE_OFFLINE";
export const FAVICON = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/awign-favicon.ico";
export const LOGO_ICON_BLUE = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/logo-icon-blue.png";
export const LOGO_ICON_WHITE = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/logo-icon-white.png";
export const LOGO_NAME_BLUE = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/logo-name-blue.svg";
export const LOGO_NAME_WHITE = "/images/awign-business-white-logo.svg";
export const IC_ORG = "https://awign-production.s3.ap-south-1.amazonaws.com/ss-clients/svgs/ic_org.svg";

// Configurable settings
export const PHONE_NUMBER = "9513661681";
export const PROJECT = "PROJECT";
export const EXECUTION = "EXECUTION";
export const VERSION = "0.1.3";

const subDomain = window.location.port === "9007" ? "localhost" : /:\/\/([^/]+)/.exec(window.location.href)[1].split(".")[1];

export function getFulfillmentPlatformURL() {
  if (process.env.REACT_APP_SERVE === "local") {
    return "http://localhost:9004";
  }
  return `https://${subDomain}${process.env.REACT_APP_DOMAIN}/fulfillment`;
}
export function getRootURL() {
  if (process.env.REACT_APP_SERVE === "local") {
    return "http://localhost:9007";
  }
  return `https://${subDomain}${process.env.REACT_APP_DOMAIN}`;
}
export const SECURE = process.env.REACT_APP_SECURE;
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const CONFIGURE_URL = process.env.REACT_APP_CONFIGURE_URL;
export const PROJECT_PLATFORM_URL = process.env.REACT_APP_PROJECT_PLATFORM_URL;
export const TRACK_ID = process.env.REACT_APP_TRACK_ID;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const ROOT_URL = getRootURL();
export const AUTH_UI_URL = process.env.REACT_APP_AUTH_UI_URL;
export const CAS_API_URL = process.env.REACT_APP_CAS_API_URL;
export const BFF_API_URL = process.env.REACT_APP_BFF_API_URL;
export const IH_OMS_API_URL = process.env.REACT_APP_IH_OMS_API_URL;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const DIR_NAME = process.env.REACT_APP_DIR_NAME;
export const S3_REGION = process.env.REACT_APP_S3_REGION;
export const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
export const S3_SECRET_KEY = process.env.REACT_APP_S3_SECRET_KEY;
export const OLD_WEBSITE_URL = process.env.REACT_APP_OLD_WEBSITE_URL;
export const PROJECTS_PLATFOM_URL = process.env.REACT_APP_PROJECTS_PLATFOM_URL;
export const LISTINGS_PLATFOM_URL = process.env.REACT_APP_LISTINGS_PLATFOM_URL;
export const APPLICATIONSS_PLATFOM_URL = process.env.REACT_APP_APPLICATIONSS_PLATFOM_URL;
export const OFFICE_PLATFOM_URL = process.env.REACT_APP_OFFICE_PLATFOM_URL;
export const FULFILLMENT_PLATFOM_URL = getFulfillmentPlatformURL();
export const LOGGY_URL = process.env.REACT_APP_LOGGY_URL;
export const DOCUMENT_HELPER_API_URL = process.env.REACT_APP_DOCUMENT_HELPER_API_URL;
export const BUCKET_ORIGIN = process.env.REACT_APP_BUCKET_ORIGIN;
