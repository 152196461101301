import { handleLogoutError } from ".";
import { CAS_API_URL, GLOBAL_ERROR } from "../constant";
import customHttp from "../custom_http";

export function getMembers(org_id, data) {
  const url = `${CAS_API_URL}/client/api/v1/orgs/${org_id}/users/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data,
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchAllUsers(data) {
  const url = `${CAS_API_URL}/central/api/v1/users/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function inviteUser(org_id, data) {
  const url = `${CAS_API_URL}/client/api/v1/orgs/${org_id}/users/invite`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function editUser(org_id, user_id, role, data) {
  const url = `${CAS_API_URL}/client/api/v1/orgs/${org_id}/users/${user_id}/memberships/update_member_permission/${role}`;
  const request = customHttp({
    url,
    method: "put",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function requestSaaSAccess(data) {
  const url = `${CAS_API_URL}/client/api/v1/orgs/${data.org_id}/users/${data.id}/setup_saas`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: { domain: data.domain },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}
