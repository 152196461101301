import { combineReducers } from "redux";
import commonReducer from "./reducer_common";
import loginReducer from "./reducer_auth";
import errorReducer from "./reducer_error";
import projectsReducer from "./reducer_projects";

const rootReducer = combineReducers({
  common: commonReducer,
  error: errorReducer,
  login: loginReducer,
  projects: projectsReducer
});

export default rootReducer;
