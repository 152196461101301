import React, { Component } from "react";

export const ScreenType = {
  MOBILE: 0,
  TABLET: 1,
  DESKTOP: 2,
};

export const withScreenSizeOptions = {
  mobileLimit: 767,
  tabletLimit: 1023,
  shouldListenOnResize: true,
};

export function withGetScreen(WrappedComponent, options = withScreenSizeOptions) {
  const { mobileLimit, tabletLimit, shouldListenOnResize } = options;
  return class WithScreenSize extends Component {
    constructor(props) {
      super(props);
      this.state = { currentSize: this.getScreenSize() };
    }

    componentDidMount() {
      if (shouldListenOnResize) {
        window.addEventListener("resize", this.handleResize);
      }
    }

    componentWillUnmount() {
      if (shouldListenOnResize) {
        window.removeEventListener("resize", this.handleResize);
      }
    }

    handleResize = () => {
      const currentSize = this.getScreenSize();
      if (currentSize !== this.state.currentSize) {
        this.setState({ currentSize });
      }
    };

    getScreenSize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= mobileLimit) {
        return ScreenType.MOBILE;
      } else if (screenWidth <= tabletLimit) {
        return ScreenType.TABLET;
      } else {
        return ScreenType.DESKTOP;
      }
    };

    isMobile = () => this.state.currentSize === ScreenType.MOBILE;
    isDesktop = () => this.state.currentSize === ScreenType.DESKTOP;
    isTablet = () => this.state.currentSize === ScreenType.TABLET;

    render() {
      const { currentSize } = this.state;
      return <WrappedComponent {...this.props} currentSize={currentSize} isMobile={this.isMobile} isDesktop={this.isDesktop} isTablet={this.isTablet} />;
    }
  };
}
