import { IH_OMS_API_URL, CAS_API_URL, GLOBAL_ERROR, GLOBAL_SUCCESS, ORGANISATION } from "../constant";
import customHttp from "../custom_http";
import { handleLogoutError } from "./action_common";

export function createRequirement(data) {
  const url = `${IH_OMS_API_URL}/clients/api/v1/requirements/`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Requirement added successfully",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchRequirements(data) {
  const url = `${IH_OMS_API_URL}/clients/api/v1/requirements/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getRequirement(id, data) {
  const url = `${IH_OMS_API_URL}/clients/api/v1/requirements/${id}`;
  const request = customHttp({
    url,
    method: "get",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function updateRequirement(id, data) {
  const url = `${IH_OMS_API_URL}/clients/api/v1/requirements/${id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function updateOrganisation(id, data) {
  const url = `${CAS_API_URL}/api/v1/orgs/${id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: ORGANISATION,
          payload: response.data.data.organisation,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}
