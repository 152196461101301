import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Router, Route, Switch } from "react-router-dom";
import { validateToken, checkValidAccess, logout, validateOrg } from "./actions";
import Cookies from "universal-cookie";
import Header from "./components/shared/header/";
import Sidebar from "./components/shared/sidebar/";
import Disclaimer from "./components/shared/cookie_policy";
import OfflineObserver from "./components/shared/offline";
import { withGetScreen } from "../src/utils/withGetScreen";
/* Shared */
import { windowWidth, isEmpty } from "./components/shared/custom_html_elements/utils";
import { LOGIN, DEVICE_TYPE, GLOBAL_SUCCESS, GLOBAL_ERROR, MAINTENANCE_ERROR, FROM_URL, HIDE_OFFLINE, LOGOUT } from "./constant";
import history from "./history";
import NotFound from "./components/shared/static/not_found/not_found";
import Breadcrumbs from "./components/shared/breadcrumbs";
import RestrictedText from "./components/shared/restricted";
import "./App.scss";

/* Admin Screens */
const ScreensHome = lazy(() => import("./screens/home/"));
const ScreensAdminsHome = lazy(() => import("./screens/home/admins"));
const ScreensProjectsSettings = lazy(() => import("./screens/requirements/settings"));
const ScreensProjectsRequirements = lazy(() => import("./screens/requirements"));
const ScreensProjectNewBilling = lazy(() => import("./screens/requirements/projects/new_billing"));
const ScreensAllProjectsRequirements = lazy(() => import("./screens/requirements/allRequirements"));
const ScreensProject = lazy(() => import("./screens/requirements/projects/show"));
const ScreensSettings = lazy(() => import("./screens/orgs/show"));
const ScreensProjectReports = lazy(() => import("./screens/requirements/projects/reports"));
const ScreensReports = lazy(() => import("./screens/requirements/projects/analytics/"));
const ScreensProjectLeads = lazy(() => import("./screens/requirements/projects/leads"));
const ScreensProjectInvoices = lazy(() => import("./screens/requirements/projects/invoices"));
const ScreensOrgMembers = lazy(() => import("./screens/orgs/members"));
const ScreensLeadsShow = lazy(() => import("./screens/requirements/projects/leads/show"));
const ScreensProctor = lazy(() => import("./screens/proctor"));
const SaasProjects = lazy(() => import("./screens/saas/projects"));
const SaasListings = lazy(() => import("./screens/saas/listings"));
const SaasApplications = lazy(() => import("./screens/saas/applications"));
const SaasOffice = lazy(() => import("./screens/saas/office"));
const SaasMembers = lazy(() => import("./screens/saas/members"));
const SaasSettings = lazy(() => import("./screens/saas/settings"));
const SaaSSuccess = lazy(() => import("./screens/saas/saas-success"));
const SaaSCard = lazy(() => import("./components/shared/saas-card"));

function layoutWrapper(WrappedComponent, showHeader = true, showSidebar = true, showFooter = false, shadeBackground = false, showBreadcrumb = false, pageName) {
  return withGetScreen(
    class PP extends Component {
      render() {
        const wrapperFunction = () => (
          <div
            className={`${this.props.isMobile() ? "" : pageName === "ScreensProjectLeads" ? "leads-page-wrapper" : "min-vh-100 d-flex justify-content-between flex-column"} ${
              shadeBackground ? "shade-background" : ""
            }`}
          >
            <div>
              {showHeader && !(this.props.isMobile() && pageName === "ScreensProjectLeads") && <Header {...this.props} />}
              {!this.props.isMobile() && showBreadcrumb && <Breadcrumbs {...this.props} />}
              {!this.props.isMobile() && showSidebar && <Sidebar {...this.props} />}
              <Suspense fallback="">
                <WrappedComponent {...this.props} />
              </Suspense>
            </div>
            {showFooter && <RestrictedText />}
          </div>
        );
        return wrapperFunction();
      }
    }
  );
}

const redirects = [];

const domain = window.location.port === "9007" ? "localhost" : /:\/\/([^/]+)/.exec(window.location.href)[1].split(".")[1];
// if (domain.substring(0, 5) === "local") {
//   document.title = "India's Largest Work-as-a-Service Platform - Awign";
// } else if (domain === "proctorright") {
//   document.title = "ProctorRight | Awign";
// } else {
//   document.title = `${capitalizeFirstLetter(domain)} | Awign`;
// }

const routes = [
  {
    path: "/",
    component: ["proctorright"].includes(domain) ? layoutWrapper(ScreensProctor, false, false, false) : layoutWrapper(ScreensHome, false, false, false),
    exact: true,
  },
  {
    path: "/projects",
    component: layoutWrapper(SaasProjects, false, false, false),
    exact: true,
    saas: true,
    protected: true,
  },
  {
    path: "/listings",
    component: layoutWrapper(SaasListings, false, false, false),
    exact: true,
    saas: true,
    protected: true,
  },
  {
    path: "/applications",
    component: layoutWrapper(SaasApplications, false, false, false),
    exact: true,
    saas: true,
    protected: true,
  },
  {
    path: "/fulfillment",
    component: layoutWrapper(SaasOffice, false, false, false),
    exact: true,
    saas: true,
    protected: true,
  },
  {
    path: "/members",
    component: layoutWrapper(SaasMembers, false, false, false),
    exact: true,
    saas: true,
    protected: true,
  },
  {
    path: "/settings",
    component: layoutWrapper(SaasSettings, false, false, false),
    exact: true,
    saas: true,
    protected: true,
  },
  // {
  //   path: "/clients",
  //   component: layoutWrapper(ScreensHome, false, false, false),
  //   exact: true,
  // },
  {
    path: "/proctor",
    component: layoutWrapper(ScreensProctor, false, false, false),
    exact: true,
  },
  {
    path: "/admins/login",
    component: layoutWrapper(ScreensAdminsHome, false, false, false),
    exact: true,
  },
  {
    path: "/requirements",
    component: layoutWrapper(ScreensAllProjectsRequirements, true, true, true, true, true),
    exact: true,
    protected: true,
  },
  {
    path: "/requirements/:requirement_id/projects/billing/new",
    component: layoutWrapper(ScreensProjectNewBilling, true, true, false, true, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain",
    component: layoutWrapper(ScreensProjectsRequirements, true, true, false, true, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/requirements",
    component: layoutWrapper(ScreensProjectsRequirements, true, true, true, true, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/requirements/:requirement_id/settings",
    component: layoutWrapper(ScreensProjectsSettings, true, true, false, true, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/requirements/:requirement_id",
    component: layoutWrapper(ScreensProject, true, true, false, false, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/requirements/:requirement_id/:access_type/projects/:project_id/executions/:execution_id/roles/:role_name/leads",
    component: layoutWrapper(ScreensProjectLeads, true, true, false, false, true, "ScreensProjectLeads"),
    exact: true,
    protected: true,
  },
  {
    path: "/:access_type/executions/:execution_id/roles/:role_id/projects/:project_id/leads/:lead_id/status/:status",
    component: layoutWrapper(ScreensLeadsShow, true, true, false, false, true, "ScreensProjectLeads"),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/requirements/:requirement_id/projects/:project_id/invoices",
    component: layoutWrapper(ScreensProjectInvoices, true, true, false, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/requirements/:requirement_id/:access_type/projects/:project_id/executions/:execution_id/roles/:role_name/analytics",
    component: layoutWrapper(ScreensReports, true, true, false, true, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/requirements/:requirement_id/projects/:project_id/reports",
    component: layoutWrapper(ScreensProjectReports, true, true, false, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/settings",
    component: layoutWrapper(ScreensSettings, true, true, false, true, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/members",
    component: layoutWrapper(ScreensOrgMembers, true, true, false, true, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:domain/saas_success",
    component: layoutWrapper(SaaSSuccess, true, true, false, false),
    exact: true,
    protected: true,
  },
  {
    path: "*",
    component: layoutWrapper(NotFound),
    exact: true,
  },
];

const cookies = new Cookies();

export const RouteWithSubRoutes = (route) => <Route exact={route.exact} path={route.path} render={(props) => <route.component {...props} />} />;

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      cookies.get("currentUser") ? (
        <>
          <Component {...props} />
          <Suspense fallback="">
            <SaaSCard {...props} />
          </Suspense>
        </>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const currentUserData = cookies.get("currentUser") && cookies.get("currentUser").data.data.user;
const isSaaSClient = currentUserData && currentUserData.roles.includes("saas-client");
const bound_saas_org_id = isSaaSClient && currentUserData.bound_saas_org_id;
const isSaaSRoute = bound_saas_org_id === domain || domain.substring(0, 5) === "local";

const SaasRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isSaaSRoute ? <Component {...props} /> : <Redirect to={domain === "business" ? { pathname: "/" } : { pathname: "/", state: { from: props.location } }} />)}
  />
);

const getRoutes = (customRoutes = routes) =>
  customRoutes.map((route, i) =>
    route.saas ? <SaasRoute key={i} {...route} /> : route.protected ? <ProtectedRoute key={i} {...route} /> : <RouteWithSubRoutes key={i} {...route} />
  );

const getRedirects = (customRedirects = redirects) => customRedirects.map((redirect, i) => <Redirect from={redirect.from} to={redirect.to} key={i} />);

class Parent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      freshChatScriptLoaded: false,
      currentUserSet: false,
      homePage: false,
    };
  }

  // All logic for org, login and valid access
  readCookies = () => {
    let access_token = cookies.get("currentUser");
    return access_token ? true : false;
  };

  setCurrentUserAndRedirect(fromUrl) {
    console.log("Login details found");
    this.props.validateToken().then((returnValue) => {
      if (returnValue && returnValue.status === "successStatus") {
        this.validateAccessAndRedirect(returnValue.message.data.data.user, fromUrl);
        return true;
      } else {
        return false;
      }
    });
  }

  validateAccessAndRedirect(currentUser) {
    // Checking valid access here as user is logged in.
    const from_url = localStorage.getItem("fromUrl");
    let that = this;
    let has_valid_access = false;
    this.setState({ currentUserSet: true, homePage: false }, () => this.setFreshChatUser());
    if (!isEmpty(currentUser) && currentUser.roles.some((role) => ["int-client", "int-ce", "int-finance", "int-sales"].includes(role))) {
      has_valid_access = true;
    } else if (isSaaSClient) {
      const ALL_SAAS_ROLES = [
        "saas-account-owner",
        "saas-account-admin",
        "saas-senior-management-team",
        "saas-central-configuration-team",
        "saas-recruiter",
        "saas-project-owner",
        "saas-manager",
      ];
      const { bound_saas_org_id } = currentUser;
      const membership = currentUser.memberships.find((elm) => elm.domain === bound_saas_org_id) || {};
      const saas_roles = membership.roles || {};
      const saas_role = Object.keys(saas_roles).some((elm) => ALL_SAAS_ROLES.includes(elm));
      if (saas_role) {
        has_valid_access = true;
      }
    }
    const bound_saas_org_id = currentUser && currentUser.bound_saas_org_id;
    if (domain.substring(0, 5) !== "local" && domain !== "business" && bound_saas_org_id && bound_saas_org_id !== domain) {
      has_valid_access = false;
    } else if (!isEmpty(currentUser) && domain.substring(0, 5) !== "local" && !bound_saas_org_id && domain !== "business") {
      has_valid_access = false;
    }
    that.props.checkValidAccess(has_valid_access);
    if (from_url) {
      localStorage.removeItem("fromUrl");
      history.push(from_url);
    }
  }

  UNSAFE_componentWillMount() {
    this.setUser();
    // Checking again with different variable name since it is getting set recursively with fromUrl because of the variable assigned at the top.
    const checkFromUrl = localStorage.getItem("fromUrl");
    if (checkFromUrl && checkFromUrl !== "undefined") {
      this.props.setFromUrl(checkFromUrl);
    }
    this.props.setDeviceType(windowWidth());
  }

  setUser = () => {
    const isCookiesSet = this.readCookies();
    const fromUrl = localStorage?.getItem("fromUrl");
    const authData = cookies.get("currentUser");
    if (authData && authData !== "undefined") {
      let currentUser = authData;
      this.props.setAuthData(currentUser);
      this.validateAccessAndRedirect(currentUser.data.data.user, fromUrl);
    } else if (isCookiesSet) {
      this.setCurrentUserAndRedirect(fromUrl);
    } else {
      // if (!["local", "proctorright", "business"].includes(domain)) {
      //   this.props.validateOrg(domain).then((returnValue) => {
      //     if (returnValue && returnValue.status === "successStatus") {
      //       // window.open(`${AUTH_UI_URL}?redirect_url=${ROOT_URL}&lm=e&tenant=clients_ui`, "_self");
      //     } else {
      //       window.open(`https://business${DOMAIN}`, "_self");
      //     }
      //   });
      // }
      this.setState({ homePage: true }, () => this.setFreshChatUser());
      this.props.checkValidAccess(true);
    }
  };

  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      window.scrollTo(0, 0);
      this.props.deleteGlobalError();
    });
    if (this.props.globalSuccess !== null) {
      this.removeGlobalSuccess();
    }
    if (this.props.globalError !== null) {
      this.removeGlobalError();
    }
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        const cookies = new Cookies();
        let currentUser = cookies.get("currentUser");
        const local_state_user_id = this.props.currentUser?.id;
        const global_state_user_id = currentUser?.data?.data?.user?.id;
        if (!global_state_user_id) {
          if (window.location.pathname === "/") return;
          history.push("/");
          this.props.deleteAuthData();
          this.props.checkValidAccess(true);
        } else if (!local_state_user_id && global_state_user_id) {
          this.setUser();
        } else if (global_state_user_id !== local_state_user_id) {
          window.location.reload();
        }
      }
    });
    if (process.env.REACT_APP_SERVE === "production") {
      const fragment = document.createDocumentFragment();
      const new_relic_script = document.createElement("script");
      // const script = document.createElement("script");
      // const link = document.createElement("link");
      new_relic_script.src = "/new_relic.js";
      new_relic_script.defer = true;
      // link.href = "https://wchat.freshchat.com/css/widget.css?t=1608527421065";
      // link.rel = "stylesheet";
      // fragment.appendChild(link);
      // script.src = "https://wchat.freshchat.com/js/widget.js";
      // script.defer = true;
      // fragment.appendChild(script);
      // script.onload = () => {
      //   if (window.fcWidget) {
      //     this.setState({ freshChatScriptLoaded: true }, () => this.setFreshChatUser());
      //   }
      // };
      fragment.appendChild(new_relic_script);
      document.head.appendChild(fragment);
    }
  }

  setFreshChatUser = () => {
    // const { freshChatScriptLoaded, currentUserSet } = this.state;
    // const currentUserData = cookies.get("currentUser");
    // const currentUser = !isEmpty(currentUserData) && !isEmpty(currentUserData.data) && !isEmpty(currentUserData.data.data) ? currentUserData.data.data.user : {};
    // const freshChatConfig = {
    //   token: "2a085f12-ea5c-484e-86d7-bc4ebd2d6ca3",
    //   host: "https://wchat.freshchat.com",
    // };
    // if (freshChatScriptLoaded && currentUserSet) {
    //   if (window.fcWidget && !isEmpty(currentUser) && currentUser.roles.some((role) => ["int-ce", "int-finance", "int-sales", "saas-client"].includes(role))) {
    //     window.fcWidget.init(freshChatConfig);
    //     window.fcWidget.setExternalId(currentUser["id"]);
    //     window.fcWidget.user.setFirstName(currentUser["name"]);
    //     window.fcWidget.user.setEmail(currentUser["email"]);
    //     window.fcWidget.user.setPhone(currentUser["mobile_number"]);
    //   }
    // }
  };

  componentDidUpdate(prevProps) {
    if (this.props.globalSuccess !== null) {
      this.removeGlobalSuccess();
    }
    if (this.props.globalError !== null) {
      this.removeGlobalError();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  renderOffline = () => {
    if (!this.props.offline.show) {
      return <div></div>;
    }
    if (this.props.offline.show) {
      return (
        <div className="parent_global_error">
          {this.props.offline.message}
          <span className="delete-error" onClick={() => this.props.hideOffline()}>
            <i className="fa fa-times"></i>
          </span>
        </div>
      );
    }
    return null;
  };

  removeGlobalError = () => {
    setTimeout(() => {
      this.props.deleteGlobalError();
    }, 30000);
  };

  renderGlobalError = () => {
    if (this.props.globalError) {
      return (
        <div className="parent_global_error">
          {this.props.globalError}
          <span className="delete-error" onClick={() => this.props.deleteGlobalError()}>
            <i className="fa fa-times"></i>
          </span>
        </div>
      );
    }
    return null;
  };

  removeGlobalSuccess = () => {
    setTimeout(() => {
      this.props.deleteGlobalSuccess();
    }, 10000);
  };

  renderGlobalSuccess = () => {
    if (this.props.globalSuccess) {
      return (
        <div className="parent_global_success">
          {this.props.globalSuccess}
          <span className="delete-error" onClick={() => this.props.deleteGlobalSuccess()}>
            <i className="fa fa-times"></i>
          </span>
        </div>
      );
    }
    return null;
  };

  callLogout = () => {
    this.props.checkValidAccess(true);
    this.props.logout().then(() => {
      history.push("/");
    });
  };

  scrollToTop = () => {
    this.messageTop.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    if (this.props.hasValidAccess) {
      return (
        <>
          {this.renderOffline()}
          {this.renderGlobalSuccess()}
          {this.renderGlobalError()}
          <Router history={history}>
            <div className="parent-container">
              <OfflineObserver />
              <div
                style={{ marginTop: "0px", float: "left", clear: "both" }}
                ref={(el) => {
                  this.messageTop = el;
                }}
              />
              <Disclaimer />
              <Switch>
                {getRedirects()}
                {getRoutes()}
              </Switch>
            </div>
          </Router>
        </>
      );
    }
    return (
      <div>
        {this.renderGlobalSuccess()}
        {this.renderGlobalError()}
        {this.renderOffline()}
        <OfflineObserver />
        <div className="text-center">
          <img src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/ic_forbidden.png" alt="forbidden" />
          <h2 className="mt-1">Page Not Accessible. Please contact Awign Support to gain access to this platform.</h2>
          <span className="text-primary" onClick={() => this.callLogout()}>
            Logout
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    globalSuccess: state.error.globalSuccess,
    globalError: state.error.globalError,
    hasValidAccess: state.common.hasValidAccess,
    currentUser: state.login.currentUser,
    offline: state.error.offline,
  };
}

const mapDispatchToProps = (dispatch) => ({
  validateOrg: (data) => dispatch(validateOrg(data)),
  setAuthData: (data) => dispatch({ type: LOGIN, payload: data.data.data.user }),
  setFromUrl: (data) => dispatch({ type: FROM_URL, payload: data }),
  setDeviceType: (deviceType) => dispatch({ type: DEVICE_TYPE, payload: deviceType }),
  deleteGlobalError: () => dispatch({ type: GLOBAL_ERROR, payload: "" }),
  deleteGlobalSuccess: () => dispatch({ type: GLOBAL_SUCCESS, payload: "" }),
  deleteAuthData: () => dispatch({ type: LOGOUT, payload: null }),
  hideOffline: () => dispatch({ type: HIDE_OFFLINE, payload: null }),
  validateToken: () => dispatch(validateToken()),
  checkValidAccess: (data) => dispatch(checkValidAccess(data)),
  logout: () => dispatch(logout()),
  setMaintenanceError: () => {
    dispatch({
      type: MAINTENANCE_ERROR,
      payload: "We do not support this browser at the moment. We strongly recommend using Google Chrome browser",
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Parent);
