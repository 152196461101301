import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { showOffline, hideOffline } from "../../../actions";

const OFFLINE_MSG = "You are offline. Please check your internet connection.";

const OfflineObserver = memo((props) => {
  useEffect(() => {
    window.addEventListener("offline", () => {
      props.showOffline("error", OFFLINE_MSG, 9999999);
    });
    window.addEventListener("online", () => {
      props.hideOffline();
    });
    return () => {
      window.removeEventListener("offline", () => {});
      window.removeEventListener("online", () => {});
    };
    //eslint-disable-next-line
  }, []);
  return <React.Fragment></React.Fragment>;
});

export default connect(null, { showOffline, hideOffline })(OfflineObserver);
