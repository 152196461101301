import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: "none",
    };
  }

  render() {
    const { breadcrumbs } = this.props;
    const { viewType } = this.state;
    if (this.props.currentUser) {
      return (
        <nav aria-label="breadcrumb">
          <ol className={`breadcrumb breadcrumb-${viewType}`}>
            {breadcrumbs && breadcrumbs.length > 0 && (
              <React.Fragment>
                <img src="https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/svgs/breadcrumb_arrow.svg" alt="" className="mr-2" />
                {breadcrumbs.map(function (item, index) {
                  return (
                    <li key={index} className="breadcrumb-item">
                      {item["to"] && <Link to={item["to"]}>{item["label"]}</Link>}
                      {!item["to"] && <span>{item["label"]}</span>}
                    </li>
                  );
                })}
              </React.Fragment>
            )}
          </ol>
        </nav>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.login.currentUser,
    deviceType: state.common.deviceType,
    breadcrumbs: state.common.breadcrumbs,
  };
}

export default connect(mapStateToProps, {})(Breadcrumbs);
