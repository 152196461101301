import Cookies from "universal-cookie";
import { LOGIN, LOGOUT, DOMAIN } from "../constant";

const cookies = new Cookies();
const curUrl = DOMAIN;

const INITIAL_STATE = {
  currentUser: null
};

export default function loginReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        currentUser: action.payload
      };
    case LOGOUT:
      console.log("Logging out");
      cookies.remove("currentUser", { path: "/", domain: `${curUrl}` });
      localStorage.removeItem("fromUrl");
      localStorage.removeItem("sidebarState");
      cookies.remove("access-token", { path: "/", domain: `${curUrl}` });
      cookies.remove("uid", { path: "/", domain: `${curUrl}` });
      cookies.remove("client", { path: "/", domain: `${curUrl}` });
      cookies.remove("expires", { path: "/", domain: `${curUrl}` });
      return {
        ...state,
        currentUser: null
      };
    default:
      return state;
  }
}
